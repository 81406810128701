

































































import Vue from "vue";
import PageHeading from "@/components/PageHeading.vue";
// import TwoFactorAuthHandler from "@/components/auth/TwoFactorAuthHandler.vue";
import { BIconBoxArrowRight } from "bootstrap-vue";
import AuthService from "@/services/AuthService";

export default Vue.extend({
  name: "UserProfile",
  components: {
    PageHeading,
    // TwoFactorAuthHandler,
    BIconBoxArrowRight,
  },
  data() {
    return {
      user: {
        email: "",
        phone: "",
        firstName: "",
        lastName: "",
        filled: false,
      },
    };
  },
  props: {
    title: { type: String },
  },
  beforeMount() {
    AuthService.getContactInformationUserAuth().then((r) => {
      this.user = r.data;
      this.user.filled = true;
    });
  },
  computed: {
    userId(): number {
      const user = this.$store.getters["auth/authUser"];
      return user.id;
    },
  },
  methods: {
    logout() {
      const payload = {
        id: this.userId,
        type: "logout",
        step: "logout",
      };
      AuthService.addLog(payload);
      this.$store.dispatch("auth/logout");
    },
  },
});
