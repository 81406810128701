












import Vue from "vue";

export default Vue.extend({
  name: "PageHeading",
  props: {
    title: { type: String },
    hasBackButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
